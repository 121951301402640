import React, { useEffect } from "react";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, DarkTheme, BaseProvider } from "baseui";
import { getNightLight } from "./services/maps";
import Routes from "./routes";

const engine = new Styletron();

const breakpoints = {
  small: 769,
  medium: 1024,
  large: 1216,
};

const ResponsiveTheme = Object.keys(breakpoints).reduce((acc, key) => {
  acc.mediaQuery[key] = `@media screen and (min-width: ${breakpoints[key]}px)`;
  return acc;
}, { breakpoints, mediaQuery: {} });

function App() {
  function getTheme() {
    const theme = getNightLight() ? DarkTheme : LightTheme;
    return { ...theme, ...ResponsiveTheme };
  }

  useEffect(() => {
    window.markers = [];
  }, []);

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={getTheme()}>
        <Routes />
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
